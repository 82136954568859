import React from "react";
import { Box, Heading, Text, SimpleGrid, Image } from "@chakra-ui/react";

const sections = [
  {
    title: "Who can participate?",
    titleColor: "#ED5D43",
    content:
      "You can participate as long as you're a student (undergraduate or graduate) 18 years or older. If you are a new grad, we’d love to have you as well! We aim to be beginner-friendly, so all are welcome! ",
  },
  {
    title: "Application deadline?",
    titleColor: "#FFCA27",
    content:
        "The regular application deadline closes on 10/20 at 11:59 PM EST. Be sure to apply as soon as possible to guarantee your spot at the hackathon!",
  },
  {
    title: "When? Where?",
    titleColor: "#58C9EA",
    content:
      "Code for Good starts Saturday, October 23 at 9:00 AM EST and ends Sunday, October 24 at 6:30 PM EST. ",
    content1:
      "If attending in-person, it will be hosted on Duke University's West Campus, in and around the Engineering Quad. Most activities will be taking place in the Fitzpatrick Center (CIEMAS) and Hudson Hall. If attending virtually, all links and info will be included in our hacker guide, which will be sent out before the event begins.",
  },
  {
    title: "Hybrid hackathon?",
    titleColor: "#535ED2",
    content:
      "If you're a Duke student, you are welcome to attend the event in person. Non-Duke students are also allowed to attend the event in person. However, we will not be providing travel reimbursements for students traveling from other schools (carpoolers may reach out to our team to potentially be reimbursed for parking costs). All are open to attend the hackathon virtually. The same programming will be available for both in-person and virtual participants, so don't fret either way!",
  },

  {
    title: "What if I'm new to coding?",
    titleColor: "#FFCA27",
    content:
      "We welcome everyone to apply, regardless of your background in tech! Even if you have limited coding or technical experience, we will have plenty of talks and workshops scheduled to introduce you to a plethora of different tools and technologies. We will also have mentors available throughout the weekend to help out if you ever get stuck or run out of ideas.",
  },
  {
    title: "Who can I work with?",
    titleColor: "#53D277",
    content:
        "You are welcome to work alone, but we definitely recommend working with a team and get to know your fellow programmers from Duke and other schools! Teams can have up to five students, no exceptions. The only request that we have is that you make a new friend and learn something new by talking to each other, our mentors, and our experts!",
  },
  {
    title: "COVID policy?",
    titleColor: "#ED5D43",
    content:
      "Note: Everything is subject to change to align with Duke University’s Covid policies and regulations. ",
    content1:
      "All students who are vaccinated are welcome to join us on campus and attend the event in-person. As per current regulations, all students will be required to be masked for the entirety of the weekend (including to attend both indoor and outdoor activities/events hosted by our team). All food must be eaten outside.",
  },
  {
    title: "How can I get involved or help?",
    titleColor: "#58C9EA",
    content:
      "If you are interested in volunteering and helping out with the day-of logistics, please send us an email at hackers@hackduke.org. ",
    content1:
      "If you are interested in mentoring hackers with tech or track-specific knowledge, drop us an email at hackers@hackduke.org. ",
    content2:
      "To get involved with sponsorship opportunities, reach out to us at sponsorship@hackduke.org!",
  },
  {
    title: "What can I make?",
    titleColor: "#535ED2",
    content:
      "We welcome all software and hardware hacks that align with our tracks. Our mission is to code for social good, so make sure to create a project that is impactful for the track you have chosen! For in-person hackers, we will also have lab space and limited equipment available for hardware projects.",
  },
  {
    title: "Will there be prizes?",
    titleColor: "#ED5D43",
    content:
      "Yes! There will be grand prize and novice winners for each track. For these track winners, the reward is a donation under your name to charities and causes! In addition, there will also be many company-sponsored prizes. These often come with the requirement that you incorporate certain technologies into your project.",
  },
  {
    title: "What should I bring if I’m attending in person?",
    titleColor: "#FFCA27",
    content:
      "Bring your laptop, chargers, ethernet cables (or just use Wi-Fi), potential toiletries/sleeping bags, masks (!), as well as a spirit to Code for Good!",
  },
  {
    title: "What if I still have questions?",
    titleColor: "#53D277",
    content:
      "Shoot us an email at hackers@hackduke.org, and we'll be sure to get back to you as soon as we can! ",
  },
];

export const FAQPage = () => {
  return (
    <Box id="faq" mb="120px">
      <div className={"width-container"}>
        <Heading
          as="h5"
          fontSize={{ base: "30px", md: "30px", lg: "40px" }}
          fontWeight="bolder"
          ml={{ base: "10", md: "50", lg: "100" }}
          pb="50"
        >
          FAQ
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          spacing={50}
          ml="10%"
          mr="10%"
        >
          {sections.map((block, i) => (
            <Box key={block.title}>
              <Text
                key={i}
                fontSize={{ base: "20px", md: "22px", lg: "28px" }}
                color={block.titleColor}
                mb="12px"
              >
                {block.title}
              </Text>
              <Text fontSize="19px">{block.content}</Text>
              {block.content1 ? (
                <>
                  <br />
                  <Text fontSize="19px">{block.content1}</Text>
                </>
              ) : (
                ""
              )}
              {block.content2 ? (
                <>
                  <br />
                  <Text fontSize="19px">{block.content2}</Text>
                </>
              ) : (
                ""
              )}
            </Box>
          ))}
        </SimpleGrid>
      </div>
      <Image src="wave.svg" alt="Wave image" pt="20" />
    </Box>
  );
};
