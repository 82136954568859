import { Global } from "@emotion/react";
const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: "Rany";
      src: local("Rany"), url(./fonts/Rany.otf) format("opentype");
    }
    `}
  />
);
/* other formats include: 'woff2', 'truetype, 'opentype',
'embedded-opentype', and 'svg' */
export default Fonts;
