import React from "react";
import "./SchedulePage.css";
import { Box, Heading, Text, SimpleGrid, Image, Center, Grid } from "@chakra-ui/react";

const saturday_events = [
    {
      time: "09:00 AM",
      event: "Check-in",
    },
    {
      time: "11:00 AM",
      event: "Opening Ceremony",
    },
    {
      time: "12:30 PM",
      event: "Sponsor Fair and Team Formation",
    },
    {
      time: "01:00 PM",
      event: "Lunch",
    },
    {
      time: "01:30 PM",
      event: "Hacking Begins!",
    },
    {
      time: "02:00 PM",
      event: "Talks and Workshops",
    },
    {
      time: "03:00 PM",
      event: "Puppy Kindergarten!",
    },
    {
      time: "06:30 PM",
      event: "Dinner",
    },
    {
      time: "08:00 PM",
      event: "Social Events",
    },
    {
      time: "12:00 AM",
      event: "Midnight Snack",
    }
]

const sunday_events = [
      {
        time: "08:30 AM",
        event: "Light Breakfast",
      },
      {
        time: "10:00 AM",
        event: "Talks and Workshops",
      },
      {
        time: "11:30 AM",
        event: "Lunch",
      },
      {
        time: "01:00 PM",
        event: "Hacking Ends",
      },
      {
        time: "01:30 PM",
        event: "Judging",
      },
      {
        time: "05:00 PM",
        event: "Closing Ceremony",
      }
]

export const SchedulePage = () => {
  return (
    <Box id="schedule" mb="120px">
        <Heading
          as="h5"
          fontSize={{ base: "30px", md: "30px", lg: "40px" }}
          fontWeight="bolder"
          pb="50"
          id="schedule-header"
        >
          Schedule
        </Heading>
        <Image 
            src={`/schedule/HeaderUnderline.svg`}
            ml="auto" 
            mr="auto" 
            boxSize="13%"
            minWidth="180px"
            id="header-image" 
        />
        <Center>
            <Grid 
                templateColumns="repeat(2, 1fr)" 
                gap={5} 
                mt="5%"
                className={"schedule-group"}
            >
                <Box>
                    <Text
                    fontSize={{ base: "20px", md: "22px", lg: "28px" }}
                    mb="12px"
                    align="center"
                    >
                    Saturday, October 23rd
                    </Text>
                    <Grid 
                    templateColumns="repeat(2, 1fr)" 
                    gap={10} 
                    mt="5%"
                    >
                        <Box>
                            {saturday_events.map((block, i) => (
                            <Text
                            fontSize={{ base: "14px", md: "16px", lg: "19px" }}
                            textAlign="right"
                            >
                                {block.time}
                            </Text>
                            ))}
                        </Box>
                        <Box>
                            {saturday_events.map((block, i) => (
                            <Text
                            fontSize={{ base: "14px", md: "16px", lg: "19px" }}
                            >
                                {block.event}
                            </Text>
                            ))}
                        </Box>
                    </Grid>
                </Box>
                <Box className={"schedule-sunday"}>
                    <Text
                    fontSize={{ base: "20px", md: "22px", lg: "28px" }}
                    mb="12px"
                    align="center"
                    >
                    Sunday, October 24th
                    </Text>
                    <Grid 
                    templateColumns="repeat(2, 1fr)" 
                    gap={10} 
                    mt="5%"
                    >
                        <Box>
                            {sunday_events.map((block, i) => (
                            <Text
                            fontSize={{ base: "14px", md: "16px", lg: "19px" }}
                            textAlign="right"
                            >
                                {block.time}
                            </Text>
                            ))}
                        </Box>
                        <Box>
                            {sunday_events.map((block, i) => (
                            <Text
                            fontSize={{ base: "14px", md: "16px", lg: "19px" }}
                            >
                                {block.event}
                            </Text>
                            ))}
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Center>
        <Center>
            <Grid 
            templateColumns="repeat(3, 1fr)" 
            gap={50} 
            mt="80px"
            >
                <Box>
                    <Image ml="auto" mr="auto" maxWidth="90%" h="auto" src={`/schedule/ScheduleImageOne.svg`} />
                </Box>
                <Box>
                    <Image ml="auto" mr="auto" maxWidth="90%" h="auto" src={`/schedule/ScheduleImageThree.svg`} />
                </Box>
                <Box>
                    <Image ml="auto" mr="auto" maxWidth="90%" h="auto" src={`/schedule/ScheduleImageTwo.svg`} />
                </Box>
            </Grid>
        </Center>            
    </Box>
  );
};
