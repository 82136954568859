import React from "react";
import { Flex, Heading, Button, Spacer } from "@chakra-ui/react";
import { IconBar } from "./IconBar";

export const PageHeader = (props) => {
  return (
    <Flex {...props} h="60px" alignItems="flex-end">
      <Heading
        ml={{ base: "10", md: "50", lg: "100" }}
        mt="5vh"
        alignItems="flex-end"
      >
        <a style={{ textDecoration: "none", fontSize: "25px" }} href="https://www.hackduke.org">
          HackDuke
        </a>
      </Heading>
      <Spacer />
      <IconBar mr={{ base: "10", md: "50", lg: "170" }} />
    </Flex>
  );
};
