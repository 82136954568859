import React from "react";
import "./AboutPage.css";
import "../shared.css";
import { Box, Heading, Image, Text, SimpleGrid, Center } from "@chakra-ui/react";

export const AboutPage = () => {
  return (
    <Box id="about" mb="150px" className="about width-container" bgRepeat="no-repeat">
      <Heading
        as="h5"
        fontSize={{ base: "30px", md: "30px", lg: "40px" }}
        fontWeight="bolder"
        ml={{ base: "10", md: "50", lg: "100" }}
        pb="50"
      >
        What is HackDuke?
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2}}
        spacing="50px"
        ml="5%"
        mr="5%"
      >
        <Box>
          <Image
            src={`/about/AboutImageOne.png`}
            ml="auto"
            mr="auto"
            mt={{ base: "30", md: "125", lg: "125" }}
            mb="auto"
            w={{ base: "60%", md: "70%", lg: "70%" }}
          />
        </Box>
        <Box>
          <Image src={`/about/BarTwo.png`} ml="auto" mr="auto" w={{ base: "90%", md: "70%", lg: "70%" }}/>
          <Heading
            as="h3"
            fontSize={{ base: "24px", md: "27px", lg: "30px" }}
            fontWeight="semi-bold"
            mt={{ base: "1vh", md: "1.5vh", lg: "2vh" }}
            ml="17.5%"
            mb="0"
            w={{ base: "80%", md: "60%", lg: "65%" }}
            ml="auto"
            mr="auto"
          >
            The nation's premier hackathon for tech and social good.
          </Heading>
          <Text
            as="h3"
            fontSize={{ base: "16px", md: "18px", lg: "20px" }}
            fontWeight="semi-bold"
            mt={{ base: "1vh", md: "1.5vh", lg: "2vh" }}
            ml={{ base: "10%", md: "17.5%", lg: "17.5%" }}
            mb="0"
            maxWidth={{ base: "80%", md: "65%", lg: "65%" }}
          >
            We unite students of all backgrounds to come together and create meaningful hacks in 24 hours
            that change the intention and narrative behind how technology is used today. Come join us on
            October 23-24 to learn, collaborate, and code for good!
          </Text>
          <Image
            src={`/about/BarTwo.png`}
            ml="auto"
            mr="auto"
            w="70%"
            mt="4%"
            w={{ base: "90%", md: "70%", lg: "70%" }}
          />
        </Box>
        <Box>
          <Image src={`/about/BarOne.png`} ml="auto" mr="auto" w={{ base: "90%", md: "70%", lg: "70%" }} />
          <Heading
            as="h3"
            fontSize={{ base: "24px", md: "27px", lg: "30px" }}
            fontWeight="semi-bold"
            mt={{ base: "1vh", md: "1.5vh", lg: "2vh" }}
            ml="17.5%"
            mb="0"
            w={{ base: "80%", md: "60%", lg: "65%" }}
            ml="auto"
            mr="auto"
          >
            Putting an emphasis on teamwork and impact.
          </Heading>
          <Text
            as="h3"
            fontSize={{ base: "16px", md: "18px", lg: "20px" }}
            fontWeight="semi-bold"
            mt={{ base: "1vh", md: "1.5vh", lg: "2vh" }}
            ml={{ base: "10%", md: "17.5%", lg: "17.5%" }}
            mb="0"
            maxWidth={{ base: "80%", md: "65%", lg: "65%" }}
          >
            We believe hackathons are more than just about building cool projects. It’s also a place
            to discuss, share, and bring to life ideas that make a difference. Look forward to working with
            non-profits, coding alongside industry experts, and above all, collaborating with your peers
            to create something truly amazing!
          </Text>
          <Image
            src={`/about/BarOne.png`}
            ml="auto"
            mr="auto"
            w="70%"
            mt="4%"
            w={{ base: "90%", md: "70%", lg: "70%" }}
          />
        </Box>
        <Box>
          <Image
            className={"about-logos-bottom"}
            src={`/about/AboutImageThree.png`}
            w="35%"
          />
          <Image
            className={"about-logos-bottom-two"}
            src={`/about/AboutImageTwo.png`}
            w="35%"
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};
