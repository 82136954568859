import React from "react";
import { Box, Heading, Text, Button, Image} from "@chakra-ui/react";
import SpeakerSlider from "../Components/SpeakerSlide";

export const SpeakerPage = () => {
  return (
    <Box mb="150px" className={"width-container"}>
      <Image src={`/speakers/headpic.svg`} alt="header pic" w="100%" mb={{ base: "10", md: "45", lg: "90"}}/>
      <Heading
        className={"speakers"}
        as="h5"
        fontSize={{ base: "30px", md: "30px", lg: "40px" }}
        fontWeight="bolder"
        ml={{ base: "10", md: "50", lg: "100" }}
      >
        Speakers
      </Heading>

      <Text
        fontSize={{ base: "16px", md: "18px", lg: "22px" }}
        mt={{ base: "10px", md: "20px", lg: "30px" }}
        ml={{ base: "10", md: "50", lg: "100" }}
        mb={{ base: "15", md: "55", lg: "110" }}
        maxWidth="80%"
      >
        HackDuke brings in industry leaders, startup founders, and other
        impressive humans for talks and workshops. Learn, grow, and get
        inspired.
      </Text>
      <Box
        ml={{ base: "10", md: "50", lg: "110" }}
        mr={{ base: "10", md: "50", lg: "100" }}
      >
        <SpeakerSlider />
      </Box>
    </Box>
  );
};
