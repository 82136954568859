import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Rany",
    body: "Rany",
  },
  styles: {
    global: (props) => ({
      "html, body": {
        backgroundColor: props.colorMode === "dark" ? "#1a202c" : "#fbf9f7",
      },
    }),
  },
});

export default theme;
