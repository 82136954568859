import React, { useEffect, useState } from "react";
import { HomePage } from "./HomePage/HomePage";
import { SponsorPage } from "./SponsorPage/SponsorPage";
import { NavBar } from "./Components/NavBar";
import { PageHeader } from "./Components/PageHeader";
import { ChakraProvider } from "@chakra-ui/react";
import Fonts from "./fonts/fonts";
import theme from "./theme";
import "./shared.css";
import { SpeakerPage } from "./SpeakerPage/SpeakerPage";
import { FAQPage } from "./FAQPage/FAQPage";
import { TrackPage } from "./TrackPage/TrackPage";
import { AboutPage } from "./AboutPage/AboutPage";
import { SchedulePage } from "./SchedulePage/SchedulePage";
import { HackPage } from './HackPage/HackPage';

function App() {
  const [displayNav, setDisplayNav] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setDisplayNav(e.target.documentElement.scrollTop > 10);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [displayNav]);
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {displayNav ? <NavBar show={displayNav} /> : <PageHeader />}
      <HomePage />
      <AboutPage />
      <TrackPage />
      <SpeakerPage />
      <HackPage />
      <SchedulePage />
      <FAQPage />
      <SponsorPage />
    </ChakraProvider>
  );
}

export default App;
