import React from "react";
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper.min.css";
import "./SpeakerSlide.css";

// import Swiper from 'swiper';

SwiperCore.use([A11y, Autoplay]);

const speakers = [
  {
    src: "elaine.png",
    name: "Elaine Zhou",
    title: "CTO of Change.org"
  },
  {
    src: "jason.png",
    name: "Jason O'Meara",
    title: "VP of Enterprise Data & Solutions Architecture at Pfizer"
  },
  {
    src: "brooks.png",
    name: "Brooks Bell",
    title: "Founder of Brooks Bell and Board Member of CDC Foundation"
  },
  {
    src: "isaac.png",
    name: "Isaac Park",
    title: "CEO at Pathos Ethos",
  },
  {
    src: "matt.png",
    name: "Matt Pleatman",
    title: "Senior Manager, Launch Site Quality & Build Reliability at SpaceX",
  },
  {
    src: "scott.png",
    name: "Scott Gorlick",
    title: "Operator & Investor and First 100 at Uber",
  },
  {
    src: "jane.png",
    name: "Jane Zhang",
    title: "Chief of Staff at Contrary Capital",
  },
  {
    src: "ola.png",
    name: "Owen Astrachan",
    title: "Professor of the Practice of CS at Duke University"
  },
  {
    src: "eric.png",
    name: "Eric Rohlfing",
    title: "Energy Executive in Residence at Duke University"
  },
  {
    src: "sandra.png",
    name: "Sandra Bermond",
    title: "Program Manager at the Duke Innovation Co-Lab",
  },
  {
    src: "mark.png",
    name: "Mark Jackson",
    title: "Quantum Evangelist at Cambridge Quantum Computing",
  },
];

function SpeakerSlider() {
  // //for changing CSS of active slides in slider
  // const [first, changeFirst] = useState(1);
  // const [last, changeLast] = useState(1);
  //
  // const changeCSS = e => {
  //     changeFirst(e.realIndex);
  //     changeLast((e.activeIndex-1)%16);
  // }
  //
  // const checkBetween = index => {
  //     if (index > first && index < last) return true;
  //     if (first > last) {
  //         if (index > first) return true;
  //         if (index < first && index < last) return true;
  //     }
  //     return false;
  // }

  return (
    <Swiper
      updateOnWindowResize
      autoplay={{ delay: 3000 }}
      speed={1000}
      slidesPerView={2}
      spaceBetween={30}
      breakpoints={{
        768: { slidesPerView: 3 },
        1024: { slidesPerView: 3 },
        1260: { slidesPerView: 4 },
        1600: { slidesPerView: 4 },
      }}
      //onSlideChange={changeCSS}
      loop
    >
      {speakers.map((block) => (
        <SwiperSlide key={block.name}>
          <div className={"speaker-container"}>
            <div className={"speaker-imagebox"}>
              <img className={"speaker-image"} src={`speakers/${block.src}`} alt="" />
            </div>
            <div className={"speaker-textbox"}>
              <h1 className={"speaker-name"}>{block.name}</h1>
              <p className={"speaker-title"}>{block.title}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SpeakerSlider;
