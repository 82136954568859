import React from "react";
import "./HomePage.css";
import "../shared.css";
import {
  Box,
  Heading,
  Link,
  Button,
  Text,
  useColorMode,
} from "@chakra-ui/react";

export const HomePage = () => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <Box h="90vh" className="landing" bgRepeat="no-repeat">
        <div className={"width-container"}>
          <Heading
            as="h1"
            fontSize={{ base: "32px", md: "50px", lg: "100px" }}
            fontWeight="bolder"
            pt={{ base: "15vh", md: "20vh", lg: "25vh" }}
            ml={{ base: "10", md: "50", lg: "100" }}
          >
            Code for Good
          </Heading>
          <Heading
            as="h3"
            fontSize={{ base: "20px", md: "30px", lg: "35px" }}
            fontWeight="600"
            mt={{ base: "0.5vh", md: "1vh", lg: "2vh" }}
            ml={{ base: "10", md: "50", lg: "100" }}
            mb="0"
          >
            October 23 - 24, 2021
          </Heading>
          <Heading
            as="h3"
            fontSize={{ base: "16px", md: "18px", lg: "22px" }}
            fontWeight="normal"
            mt={{ base: "1vh", md: "1.5vh", lg: "2vh" }}
            ml={{ base: "10", md: "50", lg: "100" }}
            mb="0"
          >
            Engineering Quad | Duke University
          </Heading>
          <Link
            style={{ textDecoration: "none" }}
            href="https://hdspring21.notion.site/HackDuke-2021-Hacker-Guide-6174e59c13fc48ef998b8fdd83b338c7"
            target={"_blank"}
          >
            <Button
              ml={{ base: "10", md: "50", lg: "100" }}
              mt={{ base: "2vh", md: "3vh", lg: "4vh" }}
              bg="transparent"
              border={`3px solid ${isDark ? "#FFFFFF" : "#000000"}`}
              borderRadius={0}
              p="30px 90px 30px"
              className={"main-home-button"}
            >
              <Text fontSize={25} fontWeight="500">
                Hacker Guide
              </Text>
            </Button>
          </Link>
        </div>
    </Box>
  );
};
