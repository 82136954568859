import React, {useState} from "react";
import "./SponsorPage.css";
import {
  Box,
  Heading,
  Link,
  Button,
  Center,
  Text,
  Image,
  useColorMode,
} from "@chakra-ui/react";

// 3000
const lineage = { img: "lineage.svg", link: "https://www.lineagelogistics.com/" };
const rv = { img: "rv.svg", link: "https://www.redventures.com/" };
const pratt = { img: "pratt.svg", link: "https://pratt.duke.edu/" };
const iid = { img: "iid.png", link: "https://bigdata.duke.edu/" };

// 2500
const oue_sa = { img: "oue_sa.png", link: "https://studentaffairs.duke.edu/" };

// 2000
const accenture = { img: "accenture.svg", link: "https://www.accenture.com/" };
const capitalone = { img: "capitalone.svg", link: "https://www.capitalone.com/" };
const db = { img: "db.svg", link: "https://www.db.com/index?language_id=1" };
const optum = { img: "optum.svg", link: "https://www.optum.com/" };
const oit = { img: "oit.png", link: "https://oit.duke.edu/" };

// 1000
const js = { img: "js.png", link: "https://www.janestreet.com/" };
const cs = { img: "cs.png", link: "https://cs.duke.edu/" };
const fuqua = { img: "fuqua.png", link: "https://www.fuqua.duke.edu/" };
const ece = { img: "ece.png", link: "https://ece.duke.edu/" };
const biostat = { img: "biostat.png", link: "https://biostat.duke.edu/" };
const figma = { img: "figma.svg", link: "https://www.figma.com/" };
const pathos = { img: "pathos.png", link: "https://pathosethos.com/" };
const scm = { img: "scm.svg", link: "https://www.scm-lp.com/" };

// <1000
const microsoft = { img: "microsoft.png", link: "https://www.microsoft.com/en-us/" };
const linode = { img: "linode.svg", link: "https://www.linode.com/" };
const gc = { img: "gc.png", link: "https://cloud.google.com/" };

const colab = { img: "colab.png", link: "https://colab.duke.edu/" };
const iss = { img: "iss.png", link: "https://iss.duke.edu/" };
const mems = { img: "mems.svg", link: "https://mems.duke.edu/" };
const stickermule = { img: "stickermule.svg", link: "http://hackp.ac/mlh-stickermule-hackathons" };
const assembly = { img: "assembly.png", link: "https://www.assemblyai.com/" };



export const SponsorPage = () => {
  const [showMicrosoft, setShowMicrosoft] = useState(false);
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const getSponsorContent = (sponsor) => {
    return (
        <div className={"sponsors-col"}>
          <a href={sponsor.link} target={"_blank"}>
            <img className={"sponsors-img"} src={`/sponsors/${sponsor.img}`} alt={sponsor.img} />
          </a>
        </div>
    );
  };

  const getMicrosoftContent = () => {
    return (
        <div
          className={"sponsors-col"}
          onMouseEnter={() => setShowMicrosoft(true)}
          onMouseLeave={() => setShowMicrosoft(false)}
        >
          <img
            className={"sponsors-img"}
            src={`/sponsors/${microsoft.img}`}
            alt={microsoft.img}
          />
          {showMicrosoft && (
              <div className={"sponsors-microsoft"}>
                <img
                    className={"sponsors-img-msft"}
                    src={`/sponsors/${microsoft.img}`}
                    alt={microsoft.img}
                />
                <p style={{ fontSize: "18px", color: "#555" }}>Opportunities</p>
                <div>
                  <a className={"msft-link"} target="_blank" href="https://careers.microsoft.com/students/us/en/job/1085245/Software-Engineering-Full-time-Opportunities-for-University-Graduates">Software Engineering: Full-Time</a>
                  <a className={"msft-link"} target="_blank" href="https://careers.microsoft.com/students/us/en/job/1085294/Software-Engineering-Intern-Opportunities">Software Engineering: Intern</a>
                  <a className={"msft-link"} target="_blank" href="https://careers.microsoft.com/students/us/en/job/1080729/Explore-Intern-Opportunities">Explore Intern</a>
                </div>
              </div>
            )
          }
        </div>
    );
  };

  return (
    <Box id="sponsors" h="90%" className="sponsors" bgRepeat="no-repeat">
        <div className={"width-container"}>
          <Heading
            as="h5"
            fontSize={{ base: "30px", md: "30px", lg: "40px" }}
            fontWeight="bolder"
            ml={{ base: "10", md: "50", lg: "100" }}
            pb="50"
          >
            Sponsors
          </Heading>

          <div className={"sponsors-row"}>
            {getSponsorContent(lineage)}
            {getSponsorContent(pratt)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(iid)}
            {getSponsorContent(capitalone)}
            {getSponsorContent(rv)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(accenture)}
            {getSponsorContent(db)}
            {getSponsorContent(oue_sa)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(optum)}
            {getSponsorContent(figma)}
            {getSponsorContent(oit)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(js)}
            {getSponsorContent(scm)}
            {getSponsorContent(cs)}

          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(ece)}
            {getSponsorContent(pathos)}
            {getSponsorContent(fuqua)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(biostat)}
            {getMicrosoftContent()}
            {getSponsorContent(colab)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(iss)}
            {getSponsorContent(linode)}
            {getSponsorContent(mems)}
          </div>

          <div className={"sponsors-row"}>
            {getSponsorContent(gc)}
            {getSponsorContent(stickermule)}
            {getSponsorContent(assembly)}
          </div>


          <Center>
            <Link
              style={{ textDecoration: "none" }}
              href="https://hackduke.typeform.com/to/SmJxjTqs"
              target={"_blank"}
            >
              <Button
                mt={{ base: "60px", md: "90px", lg: "100px" }}
                bg="transparent"
                border={`3px solid ${isDark ? "#FFFFFF" : "#000000"}`}
                borderRadius={0}
                p="30px 90px 30px"
              >
                <Text fontSize={25} fontWeight="500">
                  Apply Now
                </Text>
              </Button>
            </Link>
          </Center>
          <Center>
            <Text
              as="h3"
              fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              fontWeight="normal"
              mb="400px"
              mt="3%"
              className={"copyright-text"}
            >
              © HackDuke 2021 — <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" target="_blank">Code of Conduct</a>
            </Text>
          </Center>
        </div>
    </Box>
  );
};
