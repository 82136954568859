import { CgFacebook } from "react-icons/cg";
import { GrInstagram } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { Flex, Link, Icon, Button } from "@chakra-ui/react";
import { DarkModeSwitch } from "./DarkModeSwitch";

export const IconBar = (props) => (
  <Flex direction="row" alignItems="flex-end" {...props}>
    <DarkModeSwitch backgroundColor="transparent" />
    <Link href="https://www.facebook.com/hackduke/">
      <Button bg="transparent">
        <Icon as={CgFacebook} w={5} h={5}></Icon>
      </Button>
    </Link>
    <Link href="https://www.instagram.com/hackduke/">
      <Button bg="transparent">
        <Icon as={GrInstagram} w={5} h={5} />
      </Button>
    </Link>
    <Link href="https://twitter.com/hackduke/">
      <Button bg="transparent">
        <Icon as={FaTwitter} w={5} h={5} />
      </Button>
    </Link>
  </Flex>
);
