import {
  Flex,
  Fade,
  Box,
  Spacer,
  useColorMode,
  Image,
  Link as Ulink
} from "@chakra-ui/react";
import {Link, animateScroll as scroll } from "react-scroll";
import "./NavBar.css";
import { IconBar } from "./IconBar";

export const NavBar = ({ show }) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <Box h="90px">
      <Fade in={show}>
        <Flex
          pt="12px"
          pb="12px"
          pl="60px"
          pr="40px"
          pos="fixed"
          w="100%"
          fontSize="24"
          alignItems="center"
          bg={isDark ? "#1a202c" : "#fbf9f7"}
          zIndex={9999}
          className={"nav-bar"}
          // style={{ backdropFilter: "blur(5px)" }}
        >
          <Ulink className={"nav-link nav-extra"} mr={{ base: "10px", md: "20px", lg: "30px" }} href="https://hackduke.org" isExternal>HackDuke</Ulink>
          <Link className={"nav-link"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='about' smooth={true} offset={-80}>About</Link>
          <Link className={"nav-link nav-extra"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='tracks' spy={true} smooth={true} offset={-80}>Tracks</Link>
          <Link className={"nav-link nav-extra"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='speakers' spy={true} smooth={true} offset={-80}>Speakers</Link>
          <Link className={"nav-link nav-extra"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='hacks' spy={true} smooth={true} offset={-80}>Hacks</Link>
          <Link className={"nav-link"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='schedule' spy={true} smooth={true} offset={-80}>Schedule</Link>
          <Link className={"nav-link"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='faq' spy={true} smooth={true} offset={-80}>FAQ</Link>
          <Link className={"nav-link"} mr={{ base: "10px", md: "20px", lg: "30px" }} to='sponsors' spy={true} smooth={true} offset={-80}>Sponsors</Link>
          <Spacer />
          <IconBar mr="120px" />
        </Flex>
      </Fade>
    </Box>
  );
};
