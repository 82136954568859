import React from "react";
import "./HackPage.css";
import {Box, Heading} from '@chakra-ui/react';

const titles = {
    software: "Software",
    hardware: "Hardware",
    quantum: "Quantum",
};

const descriptions = {
    software:
        "Your typical coding-related hack. Everything is fair game: apps, websites, chrome extensions, machine learning projects—anything you can think of!",
    hardware:
        "Do you enjoy working with Arduinos, Raspberry Pis, breadboards, or even autonomous robots? If so, then a hardware hack may definitely be for you!",
    quantum:
        "Delve into the up-and-coming world of quantum by incorporating it into your project this year! Attend the following workshop to learn more: https://fb.me/e/1SNuhElhg.",
};

export const HackPage = () => {
  return (
    <Box id="hacks" h="90%" className="hacks" mb="50px">
        <div className="width-container">
            <Heading
                as="h5"
                fontSize={{ base: "30px", md: "30px", lg: "40px" }}
                fontWeight="bolder"
                ml={{ base: "10", md: "50", lg: "100" }}
                pb="50"
            >
                Hacks
            </Heading>
            <div className="hack-row">
                {Object.keys(titles).map((key) => (
                    <div key={`hack-${key}`} className={"hack-col"}>
                        <h2 className={"hack-title"}>{titles[key]}</h2>
                        <img
                            className={"hack-img"}
                            src={`/hacks/${key}.svg`}
                            alt={`Image representing a ${key} hack`}
                        />
                        {key === "quantum" ? (
                            <p className={"hack-desc"}>
                                Delve into the up-and-coming world of quantum by incorporating it into your project this year!
                                Attend <a target="_blank" style={{ textDecoration: "underline" }} href="https://fb.me/e/1SNuhElhg">this DuQIS workshop</a> on 10/17 to learn more.
                            </p>
                        ) : <p className={"hack-desc"}>{descriptions[key]}</p>}
                    </div>
                ))}
            </div>
        </div>
    </Box>
  );
};
