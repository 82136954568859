import { useColorMode, Button } from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";

export const DarkModeSwitch = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <Button onClick={toggleColorMode} {...props}>
      {isDark ? <FaSun /> : <FaMoon />}
    </Button>
  );
};
