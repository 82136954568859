import React, { useState } from "react";
import "./TrackPage.css";
import "../shared.css";
import { Box, Heading, ScaleFade } from "@chakra-ui/react";

const titles = {
  env: "Environment",
  ine: "Inequality",
  edu: "Education",
  hea: "Health",
};

const descriptions = {
  env:
    "The Environment track encompasses a technical perspective to deal with large issues impacting energy use, " +
    "allocation and environmental regulations. This track has many applications, including machine learning for " +
    "resource extraction, the use of green energy and enforcing environmental policy.",
  ine:
    "The Inequality track targets problems regarding both social and economic inequality. Whether it is " +
    "creating solutions to bridge the income gap, or finding ways to reduce disparities in gender or race, " +
    "this track is built on the principle that everyone should have the capacity to reach their full potential.",
  edu:
    "The Education track pairs hackers with educators, reaching out to all levels of subjects and skills. " +
    "In addition to HackDuke sponsored Education events, projects aim to increase visibility of technology " +
    "and to introduce basic skills to the public.",
  hea:
    "The Health track allows hackers to work with various kinds of health related data, as well as connect with" +
    " mentors from the intersection of health and technology. More than ever, technology today is in a prime " +
    "position to improve the quality, efficiency, and accessibility of health and wellness.",
};

export const TrackPage = () => {
  const [show, setShow] = useState("");

  const mainContent = () => {
    return (
      <ScaleFade in={!show} initialScale={0.5}>
        <div className={"tracks-main"}>
          <img
            className={"tracks-main-image"}
            src={"/tracks/tracks.svg"}
            alt={
              "Tracks image showing education, inequality, health, and environment"
            }
          />

          <div
            className={"tracks-button tracks-env"}
            onClick={() => setShow("env")}
          />
          <div
            className={"tracks-button tracks-ine"}
            onClick={() => setShow("ine")}
          />
          <div
            className={"tracks-button tracks-edu"}
            onClick={() => setShow("edu")}
          />
          <div
            className={"tracks-button tracks-hea"}
            onClick={() => setShow("hea")}
          />
        </div>
      </ScaleFade>
    );
  };

  const trackContent = () => {
    return (
      <ScaleFade in={show} initialScale={0.5}>
        <div className={"tracks-row"}>
          <div className={"tracks-col tracks-img-container"}>
            <img
              className={"tracks-img"}
              src={`/tracks/${show}.svg`}
              alt={"Image for selected track"}
            />
          </div>
          <div className={"tracks-col"}>
            <h2 className={"tracks-title"}>{titles[show]}</h2>
            <img
              className={"tracks-underline"}
              src={`/tracks/${show}-underline.svg`}
              alt={"Image underline for title text"}
            />
            {show === "hea" && (
                <p className={"tracks-desc tracks-hea-sponsor"}>
                  Presented by <a className="tracks-hea-link" target="_blank" href="https://studentaffairs.duke.edu/">Duke Student Affairs</a>
                  {' & '}
                  <a className="tracks-hea-link" href="https://undergrad.duke.edu/" target="_blank">Office of Undergraduate Education</a>
                </p>
            )}
            <p className={"tracks-desc"}>{descriptions[show]}</p>
            <button className={"button-main"} onClick={() => setShow("")}>
              Back
            </button>
          </div>
        </div>
      </ScaleFade>
    );
  };

  return (
    <Box id="tracks" minHeight="70vh" mb="120px" className="tracks width-container" bgRepeat="no-repeat">
      <Heading
        as="h5"
        fontSize={{ base: "30px", md: "30px", lg: "40px" }}
        fontWeight="bolder"
        ml={{ base: "10", md: "50", lg: "100" }}
      >
        Tracks
      </Heading>
      {/* If show is empty, show main content, otherwise, show content specific to that track */}
      {!show && mainContent()}
      {show && trackContent()}
    </Box>
  );
};
